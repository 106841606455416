import React from 'react';
import { Link } from "gatsby"
import "../../styles/super-sathi.css"
import Arrow from "../../images/super-sathi/arrow.png"
import logo from '../../images/super-sathi/logo.png'
import backarrow from "../../images/super-sathi/backarrow.png";
import { Helmet } from "react-helmet"

class Landing extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            headerShow: false,
            name: '',
            contactNumber: '',
            pincode: '',
            ready_for_invest: false,
            choose:false
        };
    }

    submitHandler = async () => {
        if (this.state.name === '') {
            alert("Please enter your name");
            return;
        }
        if (this.state.contactNumber === '' || this.state.contactNumber.length !== 10) {
            alert("Please enter 10-digit mobile number");
            return;
        }
        if (this.state.pincode === '') {
            alert("Please enter pincode");
            return;
        }
        if (this.state.choose === false) {
            alert("Please choose checkbox");
            return;
        }
        let userInfo ={
            name: this.state.name,
            contact: this.state.contactNumber,
            pincode: this.state.pincode,
            ready_for_invest:this.state.ready_for_invest
        }
        let payload = {
            Type:"Super Sathi Landing Page",
            FormData : JSON.stringify(userInfo),
            Contact:this.state.contactNumber
        }
        console.log(payload)
        const rawResponse = await fetch(' http://staging.mooofarm.com:9200/api/marketing/forms/save', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });
      const content = await rawResponse.json();
      if(content.status == "success"){
        this.setState({name:'',contactNumber:'',pincode:'',mooofeed_bag_count:'',ready_for_invest:false,choose:false})
        window.location.href = "/super-sathi-p/thankyou"
      }else {
        alert("Something went wrong. Please try again.")
      }
    }

    handleRadio = async(e) =>{
        if(e == "yes"){
            this.setState({ready_for_invest:true,choose:true})
        }else{
            this.setState({ready_for_invest:false,choose:true})
        }

    }

    render() {
        return (
            <div className='main-container super-sathi-container'>
                <Helmet>
                    <link
                        href="https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700&display=swap"
                        rel="stylesheet"
                    />
                    <link
                        rel="stylesheet"
                        href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css"
                    />
                    <link
                        rel="stylesheet"
                        type="text/css"
                        charset="UTF-8"
                        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
                    />
                    <link
                        rel="stylesheet"
                        type="text/css"
                        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
                    />
                    <link
                        rel="stylesheet"
                        href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
                    />
                    <script src="js/script.js"></script>
                    <script src="https://kit.fontawesome.com/dd15047d18.js" crossorigin="anonymous"></script>
                    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
                    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
                </Helmet>
                <div className="container">
                    <div className='row'>
                        <div className={"fixed-header"}>
                            <img 
                                src={backarrow} 
                                className="backarrow-img" 
                                onClick={()=>{window.location.href = "/super-sathi-h"}} 
                            />
                            <img src={logo} className="moofarm-logo" />
                            <div></div>
                        </div>
                    </div>
                </div>
                <div className="container super-sathi-form">
                    <div className=''>
                        <div className="form-group">
                            <label for="name">ਤੁਹਾਡਾ ਨਾਂ</label>
                            <input type="text" className="form-control" id="name" placeholder="ਨਾਂ ਇੱਥੇ ਲਿਖੋ" value={this.state.name} onChange={(e) => { this.setState({ name: e.target.value })}}/>
                        </div>
                        <div className="form-group">
                            <label for="yournumber">ਤੁਹਾਡਾ ਨੰਬਰ</label>
                            <input type="text" className="form-control" id="yournumber" placeholder="ਨੰਬਰ ਇੱਥੇ ਲਿਖੋ" value={this.state.contactNumber} pattern="[0-9]*" maxLength={10} onChange={(e) => { e.target.validity.valid ? this.setState({ contactNumber: e.target.value }) : alert("Contact number should contain Numbers only ") }} />
                        </div>
                        <div className="form-group">
                            <label for="yournumber">ਪਿਨਕੋਡ</label>
                            <input type="text" className="form-control" id="yournumber" placeholder="ਪਿਨਕੋਡ ਇੱਥੇ ਲਿਖੋ" value={this.state.pincode} onChange={(e) => { this.setState({ pincode: e.target.value }) }}/>
                        </div>
                        <div className="form-group">
                            <label for="yournumber">ਕੀ ਤੁਸੀਂ 1.5 ਲੱਖ ਨਿਵੇਸ਼ ਕਰਨ ਲਈ ਤਿਆਰ ਹੋ?</label>
                            <div className="redio-btn-group" onChange={(e)=>{this.handleRadio(e.target.value)}}>
                                <input type="radio" id="yes"  name="answer"  value="yes"  />
                                <label className="radio-btn" for="yes">
                                    <div className="checkbox-yes">
                                        <img src={require('../../images/super-sathi/right.png')} className="checked-img" />
                                    </div> ਹਾਂ 
                                </label>
                                <input type="radio" id="no" name="answer" value="no" />
                                <label className="radio-btn radio-btn2" for='no'>
                                    <div className="checkbox-yes checkbox-no">
                                        <img src={require('../../images/super-sathi/right.png')} className="checked-img" />
                                    </div>
                                    ਨਹੀਂ
                                </label>
                            </div>
                        </div>
                        <button className="submit-btn" onClick={()=>{this.submitHandler()}}>ਜਮ੍ਹਾ ਕਰੋ <img src={Arrow} className="moofeed-btn-arrow" alt="Arrow Img" /></button>
                    </div>
                </div>
            </div>
        )
    }
}

export default Landing
